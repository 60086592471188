const locale = {
	ANALYTICS: 'Analytics',
	START: 'Début',
	END: 'Fin',
	TOP_DESTINATION: 'Top de vos destinations',
	TRANSPORT_TYPE: 'Répartition de vos transports',
	IMPORT_EXPORT: 'Répartition de vos imports/exports',
	AVERAGE_TRANSPORT_BUDGET: 'Budget moyen / transport',
	AVERAGE_TRANSPORT_TIME: 'Transit time moyen',
	TRANSPORT_BUDGET: 'Budget transport',
	GEOGRAPHIC_DISTRIBUTION: 'Répartition géographique',
	DISTRIBUTION_FLOWS: 'Répartition des flux',
	TOP_CONTRACTORS: "Top donneurs d'ordres",
	TRANSPORT_WEIGHT: "Poids et prix moyen d'un colis",
	GROSS_WEIGHT_PRICE: '€/Kg moyen brut',
	GROSS_WEIGHT: 'Poids brut',
	CHARGEABLE_WEIGHT_PRICE: '€/Kg moyen chargeable',
	CHARGEABLE_WEIGHT: 'Poids chargeable',
	AVERAGE_PACKAGE: 'Nombre de colis moyen',
	MISSING_DATA: 'Donnée manquante',
	DAY: 'jour',
	DAYS: 'jours',
	HOUR: 'heure',
	HOURS: 'heures',
	THIS_MONTH: 'Ce mois-ci',
	LAST_MONTH: 'Mois dernier',
	LAST_THREE_MONTHS: 'Les 3 derniers mois',
	LAST_SIX_MONTHS: 'Les 6 derniers mois',
	THIS_YEAR: 'Cette année',
	LAST_YEAR: "L'année dernière",
	DEPARTURE: 'Départ',
	ARRIVAL: 'Arrivée'
};

export default locale;
