const locale = {
	ANALYTICS: 'Analytics',
	START: 'Start',
	END: 'End',
	TOP_DESTINATION: 'Top of your transport destination',
	TRANSPORT_TYPE: 'Distribution of your transport',
	IMPORT_EXPORT: 'Distribution of your imports/exports',
	AVERAGE_TRANSPORT_BUDGET: 'Average budget per transport',
	AVERAGE_TRANSPORT_TIME: 'Average transit time',
	TRANSPORT_BUDGET: 'Transport budget',
	GEOGRAPHIC_DISTRIBUTION: 'Geographic distribution',
	DISTRIBUTION_FLOWS: 'Distribution of flows',
	TOP_CONTRACTORS: 'Top contractors',
	TRANSPORT_WEIGHT: 'Weight and price of your packages',
	GROSS_WEIGHT_PRICE: 'Average gross price per €/Kg',
	GROSS_PRICE: 'Average raw weight',
	CHARGEABLE_WEIGHT_PRICE: 'Average Chargeable Weight',
	CHARGEABLE_WEIGHT: 'Average chargeable per €/Kg',
	AVERAGE_PACKAGE: 'Average package number',
	MISSING_DATA: 'This data is missing',
	DAY: 'day',
	DAYS: 'days',
	HOUR: 'hour',
	HOURS: 'hours',
	THIS_MONTH: 'This month',
	LAST_MONTH: 'Last month',
	LAST_THREE_MONTHS: 'Last 3 months',
	LAST_SIX_MONTHS: 'Last 6 months',
	THIS_YEAR: 'This year',
	LAST_YEAR: 'Last year',
	DEPARTURE: 'Departure',
	ARRIVAL: 'Arrival'
};

export default locale;
